<template>
  <v-card>
    <v-card-title>Reorder Tables</v-card-title>
    <v-card-subtitle>Sort tables to change in what order they appear in the calendar</v-card-subtitle>
    <v-card-actions>
      <v-btn
        class="primary"
        block
        large
        disabled
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
	name: 'ReorderTables'
}
</script>
